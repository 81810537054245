/** @jsx jsx */
import { jsx, Grid, Box, Link } from "theme-ui";
import { motion } from "framer-motion";
import NextLink from "next/link";
import fetch from "isomorphic-unfetch";
import { GraphQLClient, gql } from "graphql-request";

import Layout from "components/layouts/default";
import Product from "components/product";
import InfoBlock from "components/info-block";
import Banner from "components/banner";

import products from "data/products";
import { fadeInUp } from "data/animations";

function Home(props) {
  const { products } = props;

  return (
    <Layout>
      <Box
        mb={["xxxl", null, "xxxxl"]}
        sx={{
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Banner mb={["s", null, "m", "l"]}>Neue Goods®</Banner>
        <Banner isReversed>Digital/physical</Banner>
      </Box>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              when: "beforeChildren",
              staggerChildren: 0.1,
            },
          },
          hidden: {
            opacity: 0,
            transition: {
              when: "afterChildren",
            },
          },
        }}
      >
        <Grid
          columns={[null, ["1fr", 2], ["1fr", 3]]}
          gap={["100px 40px", null, null, "140px 50px"]}
          px={["m", null, "l"]}
          sx={{ maxWidth: 1440 }}
          mx="auto"
        >
          {products.map((product, key) => (
            <motion.div key={key} {...fadeInUp()}>
              <Product product={product} index={key} />
            </motion.div>
          ))}
        </Grid>
      </motion.div>
      <InfoBlock sx={{ pt: ["xxxl", null, "xxxxl"] }}>
        <p sx={{ my: 0 }}>
          Every physical item in this very special collection by Neue Goods® is
          also represented as tradable cryptocurrency on the Ethereum
          blockchain.
          <br />
          <NextLink href="/how-it-works" passHref>
            <Link sx={{ textDecoration: "none", color: "white.100" }}>
              Learn more →
            </Link>
          </NextLink>
        </p>
      </InfoBlock>
    </Layout>
  );
}

const fndGraphClient = () => {
  return new GraphQLClient(
    "https://api.thegraph.com/subgraphs/name/f8n/f8n-xdai"
  );
};

export const PRODUCT_QUERY = gql`
  query Product($id: String) {
    product: xykmarket(id: $id) {
      id
      symbol
      totalSupply: initialTotalSupply
      availableTokens
      redeemed
      redeemable
      totalBuyPrice
      totalSellPrice
      address
      goLiveDate
      historicalBars {
        collateralVolume
      }
      brand {
        id
        name
        symbol
      }
    }
  }
`;

export async function getStaticProps() {
  const PRODUCT_API = `https://withfoundation.com/api/product`;

  const graphClient = fndGraphClient();

  try {
    const promises = products.map(async (product) => {
      const req = await fetch(`${PRODUCT_API}/${product.id}`);
      const productData = await req.json();

      const { product: graphData } = await graphClient.request(PRODUCT_QUERY, {
        id: product.id,
      });
      return { ...productData, ...graphData };
    });

    const res = await Promise.all(promises);

    return {
      props: {
        products: res ?? [],
      },
      revalidate: 60,
    };
  } catch {
    return {
      props: {
        products: [],
      },
      revalidate: 60,
    };
  }
}

export default Home;
